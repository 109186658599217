// src/components/Login.tsx
import React from "react";
import { useTranslation } from 'react-i18next';
import { auth } from "../firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const Login: React.FC = () => {
    const { t } = useTranslation();
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log(result.user);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div>
            <button onClick={signInWithGoogle} className="login-button">{t('login')}</button>
        </div>
    );
};

export default Login;
