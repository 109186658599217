// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCeT4PKwZvxg0L4MgD3OWi28-MqnzwdWR8",
    authDomain: "kabuken-app.firebaseapp.com",
    projectId: "kabuken-app",
    storageBucket: "kabuken-app.appspot.com",
    messagingSenderId: "801295479944",
    appId: "1:801295479944:web:f2177f355a655140f51b3d",
    measurementId: "G-RXHJC5Y2S3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
