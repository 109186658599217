// src/App.tsx
import React from 'react';
import Search from './components/Search';
import MainMenu from './components/MainMenu';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import './App.css';


const App: React.FC = () => {
    const [user] = useAuthState(auth);

    return (
        <div className="App">
            {user ? (
                <>
                    <MainMenu />
                    <Search />
                </>
            ) : (
                <>
                    <MainMenu />
                </>)
            }
        </div>
    );
};

export default App;