// src/components/Search.tsx
import React, {useState} from "react";
import {useTranslation} from 'react-i18next';
import {auth} from "../firebase";
import "./Search.css";

const Search: React.FC = () => {
    const {t} = useTranslation();
    const [input, setInput] = useState('');
    const [searchSubmitted, setSearchSubmitted] = useState(false);

    const submitSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        const token = await auth.currentUser?.getIdToken();
        const response = await fetch('backend-url', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({query: input})
        });

        if (response.ok) {
            setSearchSubmitted(true);
        }
    };

    return (
        <div className="search-container">
            <form onSubmit={submitSearch}>
                <input type="text" placeholder={t('search')} className="search-input" value={input} onChange={e => setInput(e.target.value)} />
            </form>
        </div>
    );
};

export default Search;