// src/components/MainMenu.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import './MainMenu.css';
import Login from "./Login";

const MainMenu: React.FC = () => {
    const { t, i18n} = useTranslation();
    const user = auth.currentUser;

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const logout = () => {
        auth.signOut();
    };

    return (
        <div className="main-menu">
            <div className="language-switcher">
                <a href="#" onClick={() => changeLanguage('en')}>English</a> |
                <a href="#" onClick={() => changeLanguage('jp')}>Japanese</a>
            </div>
            {user && <div className="welcome-message">{t('welcome')}, {user.displayName}</div>}
            {user && <button onClick={logout} className="logout-button">{t('logout')}</button>}
            {!user && <Login />}
        </div>
    );
};

export default MainMenu;