import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/locales/en/translation.json';
import translationJP from './assets/locales/jp/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    jp: {
        translation: translationJP
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['localStorage', 'navigator']
        },
        resources,
        lng: "jp",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });